import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { OverlayTrigger, Popover } from "react-bootstrap"
import { Button, Card, CardBody, Container, Row } from "reactstrap"
import "./user.css"
import moment from "moment"
import { cilCopy } from "@coreui/icons"
import { ToastContainer, toast } from "react-toastify"
import { cilArrowThickLeft, cilTrash } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import { Tooltip } from "react-bootstrap"
import searchicon from "../../assets/images/search-line.svg"
import calendarremovelines from "../../assets/images/calendar-remove-lines.svg"
import schedule from "../../assets/images/schedule.svg"
import downloadfileicon from "../../assets/images/download-file.svg"
import DateRangePicker from "react-bootstrap-daterangepicker"
import {
  CCol,
  CButton,
  CFormSelect,
  CInputGroupText,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCard,
  CCardBody,
  CCardGroup,
} from "@coreui/react"
import axios from "axios"
import useApiStatus from "hooks/useApiStatus"
import Spinner from "loader"
import DataTable from "react-data-table-component"
import {
  fullDateFormat,
  StandardPicketDateFormat,
} from "components/Common/utility"
import { Link, useParams } from "react-router-dom"
import "bootstrap-daterangepicker/daterangepicker.css"
import { downloadFile } from "components/Common/utility"
import Swal from "sweetalert2"
import { api } from "common/api"
const debounceTime = 300
let distinctUntilChanged = null

const UserManagement = () => {
  let UserId = new URLSearchParams(window.location.search).get("id")
  const user = localStorage.getItem("authUser")
  const parseData = JSON.parse(user)
  const token = parseData?.data?.token

  const [networkStatus, setNetworkStatus] = useState("")

  const [transactionData, setTransactionData] = useState([])
  const { apiStatus, setApiSuccess, setApiFailed, changeApiStatus } =
    useApiStatus()

  const [tooltip, setTooltip] = useState(false)

  const [loader, setLoader] = useState(true)

  const [data, setData] = useState([])
  const [pageData, setPageData] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 8,
  })
  const [status, setStatus] = useState("")

  const [dateFilter, setDateFilter] = useState([])

  const [calendarIsShowing, setCalendarIsShowing] = useState(false)
  const inputRef = useRef(null)
  const [query, setQuery] = useState("")

  const dateFilterChange = (e, picker) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>.", picker)
    // console.log(picker.startDate.format("YYYY-MM-DD"), ">>>>>>>>>>>>>>>>.")

    const filter = [
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD"),
    ]
    setDateFilter(filter)
    fetchData(pageData.current, pageData.pageSize, filter, query)
  }

  const fetchData = async (
    page = 1,
    limit = 8,
    dateFilter = [],
    query = "",
    exportRequest = "false",
    networkStatus = ""
  ) => {
    try {
      changeApiStatus(true, "")
      const [startDate, endDate] =
        dateFilter.length === 0 ? ["", ""] : dateFilter

      // console.log(limit,page,"limit and page")
      const list = await api.get(
        `/transactions/getUserInstallmentPlans?userId=${UserId}`,
        { headers: { Authorization: `${token}` } }
      )
      console.log(list, "Export csv resp outer")
      if (exportRequest === "true") {
        console.log(list, "Export csv resp")
        const url = URL.createObjectURL(
          new Blob([list.data], { type: "text/csv" })
        )

        const date1 = moment(Date.now()).format("DD-MM-YYYY")
        const time1 = moment(Date).format("h:mm a")

        const finalDate = date1 + "-" + time1

        console.log(date1, "final Date ")
        downloadFile(url, `AllUSers${date1}.csv`)

        return changeApiStatus(false, "")
      }

      if (list.status === 200) {
        console.log(list, "list")
        changeApiStatus(false, "")
        setPageData({
          ...pageData,
          totalItems: list.data.data.plans,
          current: page,
        })
        setData(
          list.data.data.plans.map((val, index) => {
            return { ...val, serial: index + 1 }
          })
        )
      } else {
        console.log(list)
        throw new Error(list.error)
      }
    } catch (err) {
      console.log(err, ">>>>>>>>>>>>")
      changeApiStatus(false)
    }
  }

  const [showSuccessToolTip, setShowSuccessToolTip] = useState()
  const showHandler = i => {
    setShowSuccessToolTip(i)
    setTimeout(() => {
      setShowSuccessToolTip()
    }, 2000)
  }
  const [showSuccessToolTip1, setShowSuccessToolTip1] = useState()
  const showHandler1 = i => {
    setShowSuccessToolTip1(i)
    setTimeout(() => {
      setShowSuccessToolTip1()
    }, 2000)
  }
  const [refresApi, setRefreshApi] = useState(false)
  useEffect(() => {
    fetchData(
      pageData.current,
      pageData.pageSize,
      dateFilter,
      query,
      false,
      networkStatus
    )
    // eslint-disable-next-line
  }, [pageData.current, pageData.pageSize, networkStatus, refresApi])

  const onQueryChange = e => {
    const value = e.target.value.trim()
    setQuery(value)
    if (distinctUntilChanged) {
      clearTimeout(distinctUntilChanged)
      distinctUntilChanged = null
    }
    distinctUntilChanged = setTimeout(() => {
      setQuery(value)
      fetchData(pageData.current, pageData.pageSize, dateFilter, value)
      distinctUntilChanged = null
    }, debounceTime)
  }

  const revoke = id => {
    api
      .delete(`/transactions/revokePlan?planId=${id}`, {
        headers: { Authorization: `${token}` },
      })
      .then(res => {
        toast("success", "Successfully Revoked")
        setRefreshApi(!refresApi)
      })
      .catch(err => {
        toast("error", "Error")
      })
  }

  const columns = [
    // {
    //   name: "Sr. no",
    //   selector: row => {
    //     return row.serial + (pageData.current - 1) * pageData.pageSize
    //   },
    //   sortable: true,
    // },

    {
      name: "Booster Variant",
      selector: row => (
        <>
          <div className="align-items-center">
            <p className="mx-1 mb-0 px-4">{`${row.planName}`}</p>
          </div>
        </>
      ),
    },
    {
      name: "Total Cost",
      selector: row => `$ ${row?.totalAmount} `,
    },

    {
      name: "Amount Paid",
      selector: row => (
        <>
          <div className="align-items-center">
            <p className="mx-1 mb-0 px-4">
              {`$ ${
                row?.status === "Completed"
                  ? parseFloat(row?.totalAmount)?.toFixed(2)
                  : parseFloat(row?.paidAmountUsd)?.toFixed(2)
              }`}
            </p>
          </div>
        </>
      ),
      sortbale: true,
    },
    {
      name: "Pending Amount",
      selector: row =>
        `$ ${
          row?.status === "Completed"
            ? (
                parseFloat(row?.totalAmount) - parseFloat(row?.totalAmount)
              )?.toFixed(4)
            : (
                parseFloat(row?.totalAmount) -
                parseFloat(
                  Number(row?.paidAmountUsd) -
                    5 *
                      (Number(row?.numOfInstallmentsMonth) -
                        Number(row?.remainingInstallmentsMonth))
                )
              )?.toFixed(4)
        } `,
      sortbale: true,
    },
    {
      name: "Due Date",
      selector: row => (
        <>
          {row?.status === "Completed" ? (
            <>
              <strong className="text-black mb-2">Payment Completed</strong>
            </>
          ) : (
            <>
              <div className="d-block">
                {moment(row?.nextInstallmentDate)?.format("LL")}
              </div>
            </>
          )}
        </>
      ),
      sortbale: true,
    },

    {
      name: "Action",

      selector: row => (
        <span
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              // text: ``,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Recover it!",
            }).then(result => {
              if (result.isConfirmed) {
                revoke(row?._id)
                //   Swal.fire(
                //     'Recovered!',
                //     'Your file has been Recovered.',
                //     'success'
                //   )
              }
            })
          }}
        >
          <CButton style={{backgroundColor: row?.color}} className="hand text-white px-3 ">
            Recover Booster
          </CButton>
        </span>
      ),
    },
  ]

  return apiStatus.inProgress && !data.length ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <p style={{ color: "#2a3042", fontWeight: 500, fontSize: "17px" }}>
            {" "}
            Purchased Booster
          </p>
          <Row>
            <CCol xs>
              <div className="custom-header mb-3">
                <div className="col-12 ml-auto me-auto">
                  <div className="row justify-content-between align-items-center px-0">
                    <div className="cursor-pointer">
                      <Link to="/user-management">
                        <CIcon
                          icon={cilArrowThickLeft}
                          style={{
                            height: "28px",
                            cursor: "pointer",
                            color: "black",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
            <CCardGroup>
              <CCard>
                <CCardBody>
                  <DataTable
                    striped
                    columns={columns}
                    data={data}
                    paginationDefaultPage={pageData.current}
                    paginationPerPage={pageData.pageSize}
                    // pagination={true}
                    progressPending={apiStatus.inProgress}
                    paginationServer
                    paginationTotalRows={pageData.totalItems}
                    paginationRowsPerPageOptions={[8, 16, 24, 32]}
                    onChangePage={e => setPageData({ ...pageData, current: e })}
                    onChangeRowsPerPage={e =>
                      setPageData({ ...pageData, pageSize: e })
                    }
                    progressComponent={<Spinner />}
                  />
                </CCardBody>
              </CCard>
            </CCardGroup>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserManagement.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(UserManagement)
