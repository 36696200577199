import { CCard, CCardBody } from "@coreui/react";
import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { CircularProgressbar } from 'react-circular-progressbar';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from "axios";
import { api } from "common/api";

const Analytics = () => {
    const percentage = "99.9%";
    const [boosterSoldData, setBoosterSoldData] = useState([])
    const user = localStorage.getItem('authUser')
    const parseData = JSON.parse(user)
    const token = parseData?.data?.token;

    useEffect(() => {
        fetchData()
    },[])


    const fetchData = () => {
        api.get("/dashboard/getTotalBoosterSold", { headers: { Authorization: `${token}` } })
            .then((res) => {
                // console.log(res)
                setBoosterSoldData(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const x2Data = (boosterSoldData.remainingX2/boosterSoldData.totalX2Booster )*100

    const x3Data = (boosterSoldData.remainingX3/boosterSoldData.totalX3Booster )*100
    
    return (
        <>
            <Row>
                <Col md={6}>
                    <CCard>
                        <CCardBody>
                            <Row className="align-items-center">
                                <Col className="analytics-data" lg={4}>
                                    <CircularProgressbarWithChildren value={x2Data.toString()}>
                                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                        {/* <img style={{ width: 40, marginTop: -5 }} src="https://i.imgur.com/b9NyUGm.png" alt="doge" /> */}
                                        <div style={{ fontSize: 18, marginTop: -5 }}>
                                            <strong>{boosterSoldData.remainingX2}</strong>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </Col>
                                <Col lg={8}>
                                    <h4 className="text-center text-lg-start mt-2 mt-lg-0">X2 Boosters left</h4>
                                </Col>
                            </Row>
                        </CCardBody>
                    </CCard>
                </Col>
                <Col md={6}>
                    <CCard>
                        <CCardBody>
                            <Row className="align-items-center">
                                <Col className="analytics-data" lg={4}>
                                    <CircularProgressbarWithChildren value={x3Data.toString()}>
                                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                        {/* <img style={{ width: 40, marginTop: -5 }} src="https://i.imgur.com/b9NyUGm.png" alt="doge" /> */}
                                        <div style={{ fontSize: 18, marginTop: -5 }}>
                                            <strong>{boosterSoldData.remainingX3}</strong>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </Col>
                                <Col lg={8}>
                                    <h4 className="text-center text-lg-start mt-2 mt-lg-0">X3 Boosters left</h4>
                                </Col>
                            </Row>
                        </CCardBody>
                    </CCard>
                </Col>
            </Row>
        </>
    )
}

export default Analytics;