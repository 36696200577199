import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/smartchain/logo.png";
import smlogo from "../../assets/smartchain/spro.png"

import { Helmet } from "react-helmet";

const Sidebar = props => {

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SmartChain Pro</title>
      </Helmet>
      <div className="vertical-menu">
        <div className="navbar-brand-box" style={{ marginTop: "10px" }}>
          <Link to="/" className="logo logo-light">  
            <span className="logo-lg" style={{ objectFit: "contain" }}>
              <img src={logo} style={{ width: "192px", height: "inherit", marginRight: "31px" }}/>                   
            </span>
            <span className="logo-sm" style={{ objectFit: "contain" }}>
              <img src={smlogo} style={{ width: "40px", height: "inherit", marginLeft: "-10px" }}/>
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
