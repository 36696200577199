const BASE_URL = window.location.origin.includes("net")
  ? "https://smartchain-apis.zip2box.com/uploads/common"
  : "https://smartchain-dev-apis.zip2box.com/uploads/common"

const getApiUrl = endpoint => BASE_URL + endpoint

const BANNER_DETAILS = getApiUrl("cms/bannerdetails")
const BANNER_PUT = getApiUrl("cms/banner")
const CUSTOM_DETAILS = getApiUrl("cms/customdetails")
const CUSTOM_PUT = getApiUrl("cms/custom")
const FAQS = getApiUrl("faq/faqsdata")
const FAQS_POST = getApiUrl("faq/newfaq")
const FAQS_EDIT = getApiUrl("faq/editfaq")
const FAQS_UPDATE = getApiUrl("faq/faqupdate")

const FOOTER = getApiUrl("cms/footerdetails")
const FOOTER_PUT = getApiUrl("cms/footer")
const HEADER = getApiUrl("cms/headerdetails")
const HEADER_PUT = getApiUrl("cms/header")

import axios from "axios"
let api = axios.create({
  baseURL: window.location.origin.includes("net")
    ? "https://smartchain-apis.zip2box.com/api"
    : "https://smartchain-dev-apis.zip2box.com/api",
})
export {
  FOOTER,
  FOOTER_PUT,
  HEADER,
  HEADER_PUT,
  BANNER_DETAILS,
  BANNER_PUT,
  CUSTOM_DETAILS,
  CUSTOM_PUT,
  FAQS,
  FAQS_UPDATE,
  FAQS_POST,
  FAQS_EDIT,
  BASE_URL,
  api,
}
