import React, { useState, useEffect, useRef, useMemo } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
// Import Editor
// import { Editor } from "react-draft-wysiwyg"
import JoditEditor from 'jodit-react';
import axios from "axios"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import InputMask from "react-input-mask"
import { SketchPicker } from "react-color"
import { toast } from 'react-toastify'

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import useApiStatus from "hooks/useApiStatus"
import Spinner from "loader";
import { CCard, CCardBody } from "@coreui/react";
import "./calculator.css"
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from 'yup'
import { divide } from "lodash";
import { api } from "common/api";

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"

const LandingPageCalculator = () => {

  //meta title
  const { apiStatus, setApiSuccess, setApiFailed, changeApiStatus } = useApiStatus()
  const inpRow = [{ name: "", file: "" }]


  // const [startDate, setstartDate] = useState(new Date())
  // const [endDate, setendDate] = useState(new Date())
  // const [inputFields, setinputFields] = useState(inpRow)
  // const [colorHor, setcolorHor] = useState("#fffff");
  // const [simple_color2, setsimple_color2] = useState(0)
  // const [simple_color3, setsimple_color3] = useState(0)
  // const { apiStatus, setApiSuccess, setApiFailed, changeApiStatus } =
  //   useApiStatus()



  const [data, setData] = useState({
    totalMegaPower: "",
    boosterPlan: "",
    blockReward: "",
    conversionRate: ""
  })

  const [initialAmount, setInitialAmount] = useState('')
  const [lockPeriod, setLockPeriod] = useState('')
  const [chosenNft, setChosenNft] = useState('')
  const [nftLocked, setNftLocked]= useState('')
  const [finalMegaPower, setFinalMegaPower] = useState('')
  const [dividend, setDividend] = useState()
  const [blockchainMegaPower, setblockchainMegaPower] = useState('')

  switch (lockPeriod) {
    case "0":
      return setLockPeriod(1);
    case "1":
      return setLockPeriod(2);
    case "2":
      return setLockPeriod(4);
    case "3":
      return setLockPeriod(6);
    case "4":
      return setLockPeriod(10);
    case "5":
      return setLockPeriod(15);
  }


  switch (chosenNft) {
    case "0":
      return setChosenNft(0);
    case "2":
      return setChosenNft(2);
    case "3":
      return setChosenNft(3);
    case "5":
      return setChosenNft(5);
  }


  useEffect(() => {
    setFinalMegaPower((lockPeriod * chosenNft)  * initialAmount)
    setblockchainMegaPower(finalMegaPower+Number(data.totalMegaPower))
    setDividend(((Number(data.blockReward) / blockchainMegaPower ) * finalMegaPower).toFixed(2))
  },[chosenNft,finalMegaPower, blockchainMegaPower, dividend])

  // console.log(finalMegaPower, "finalMegaPower")
  // console.log(blockchainMegaPower, "blockchainMegaPower")
  // console.log(dividend, "dividend")


  const calcSchema = Yup.object().shape({
    totalMegaPower: Yup.string()
      //  .min(2, 'Too Short!')
      //  .max(1000, 'Too Long!')
      .required('Please enter total mega power value'),
    boosterPlan: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Please enter booster plan value'),
    blockReward: Yup.string()
      .min(3, 'Too Short!')
      .max(1000, 'Too Long!')
      .required('Please enter block rewards '),
    conversionRate: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Please enter conversion rate'),
  })

  const user = localStorage.getItem('authUser')
  const parseData = JSON.parse(user)
  const token = parseData?.data?.token;

  const onSubmit = (values) => {
    // e.preventDefault();
    changeApiStatus(true)
    // console.log(data, "jkhgfds")
    api.post("/profitspro/save",
      values, { headers: { "Authorization": ` ${token}` } })
      .then((result) => {

        setApiSuccess()
        changeApiStatus(false)
        fetchData()
        toast.success('Updated Successfully');

      })
      .catch((err) => {
        changeApiStatus(false)
        toast.error("Already Updated!!")
        console.log(err, "Banner error")
      })
    // setLoader(false)
  }

  // console.log(data, "Clac data")
  

  const fetchData = async () => {
    // changeApiStatus(true)
    await api.get("/profitspro/get", { headers: { "Authorization": ` ${token}` } })
      .then((result) => {
        console.log(result.data.data.items[0], "Calc result")
        const {
          conversionRate,
          blockReward,
          boosterPlan,
          totalMegaPower
        } = result.data.data.items[0]
        setData({
          conversionRate,
          blockReward,
          boosterPlan,
          totalMegaPower
        })
        // setData(result.data.msg);
        // console.log(result.data.msg, "Banner details");

        setApiSuccess()
        changeApiStatus(false)
      }).catch(err => {
        console.log(err);
        changeApiStatus(false)
        setApiFailed(err.message)
      })
    // setLoader(false)
  };

  useEffect(() => {

    



    changeApiStatus(true)
    fetchData()
    // setLoader(false)
  }, [setData])



  return apiStatus.inProgress ? <Spinner /> : (
    // return (

    <>
      <div className="page-content">
        <Container fluid>
          <p
            style={{ color: "#2a3042", fontWeight: 500, fontSize: "17px" }}
          >Calculator</p>
          <div className="col-xl-12 col-lg-12 mx-auto mb-xl-4 mb-2 rounded">
            <CCard className="mb-2 border-0 pt-2">
              <CCardBody>
                <div>
                  <Formik
                    initialValues={{ ...data }}
                    validationSchema={calcSchema}
                    enableReinitialize
                    onSubmit={onSubmit}
                  >
                    {({ errors, touched, setFieldValue, setTouched, values }) => (
                      <Form>
                        <div className="row" >
                          <div className="col-md-6">

                            <div>
                              <div className="d-flex my-4">
                                <div className=""  style={{width:"calc(100% - 196px)"}}>
                                  <p className="heading mt-2 fs-14">How much do you want to stake ?</p>
                                </div>
                                <div className="mx-2"  style={{width:"120px"}}>
                                  <input type="text" value={initialAmount} className="w-100 input form-control" placeholder="Enter here...." onChange={(e) => setInitialAmount(e.target.value)} />
                                </div>
                                <div className="" style={{width:"60px"}}>
                                  <p className="token mt-2 ">SPRO</p>
                                </div>
                              </div>
                              <div className="d-flex my-4">
                                <div style={{width:"calc(100% - 196px)"}}>
                                  <p className="heading mt-2 fs-14" >Lock Period ?</p>
                                </div>
                                <div className="mx-2"  style={{width:"120px"}}>
                                  <select
                                    className="form-select input cstmshadow"
                                    // value={stackTwo}
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                      setLockPeriod(e.target.value);
                                    }}
                                  >
                                    <option value="none" hidden selected>
                                      Select
                                    </option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                </div>
                                <div style={{width:"60px"}}>
                                  <p className="token mt-2">Year</p>
                                </div>
                              </div>
                              <div className="d-flex my-4">
                                <div style={{width:"calc(100% - 196px)"}}>
                                  <p className="heading mt-2 fs-14">Choose your NFT</p>
                                </div>
                                <div className="mx-2"  style={{width:"120px"}}>
                                  <select
                                    className="form-select input cstmshadow"
                                    value={nftLocked}
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                      setNftLocked(e.target.value);
                                      setChosenNft(e.target.value);
                                    }}
                                  >
                                    <option value="none" hidden selected>
                                      Select
                                    </option>
                                    <option value="0">0</option>
                                    <option value="2">NFT X2</option>
                                    <option value="3">NFT X3</option>
                                    <option value="5">NFT X5</option>
                                  </select>
                                </div>
                                <div style={{width:"60px"}}>
                                  <p className="token mt-2">SPRO</p>
                                </div>
                              </div>
                              <div className="d-flex my-4">
                                <div style={{width:"calc(100% - 196px)"}}>
                                  <p className="heading mt-2 fs-14">Your NFT gives total MegaPower</p>
                                </div>
                                <div className="mx-2"  style={{width:"120px"}}>
                                  <input type="dropdown" className="w-100 cstmshadow input form-control" value={finalMegaPower}/>
                                </div>
                                <div style={{width:"60px"}}>
                                  <p className="token mt-2">SPRO</p>
                                </div>
                              </div>
                            </div>
                          </div>



                          <div className="col-md-6">
                            <div className="d-flex my-4">
                              <div style={{width:"calc(100% - 196px)"}}>
                                <p className="heading mt-2 fs-14">Conversion rate from SPRO to $</p>
                              </div>
                              <div className="mx-2"  style={{width:"120px"}}>
                                <Field name="conversionRate" id="conversionRate" type="text" className="w-100 input form-control" placeholder="0.35"
                                />
                                {errors.conversionRate && touched.conversionRate ? (
                                  <div className="input-error text-danger">{errors.conversionRate}</div>
                                ) : null}
                              </div>
                              <div style={{width:"60px"}}>
                                <p className="token mt-2">$</p>
                              </div>
                            </div>
                            <div className="d-flex my-4">
                              <div style={{width:"calc(100% - 196px)"}}>
                                <p className="heading mt-2 fs-14">Daily Blockchain block-reward</p>
                              </div>
                              <div className="mx-2"  style={{width:"120px"}}>
                                <Field name="blockReward" id="blockReward" type="text" className="w-100 input form-control" placeholder="20,000"
                                />
                                {errors.blockReward && touched.blockReward ? (
                                  <div className="input-error text-danger">{errors.blockReward}</div>
                                ) : null}
                              </div>
                              <div style={{width:"60px"}}>
                                <p className="token mt-2">SPRO</p>
                              </div>
                            </div>
                            <div className="d-flex my-4">
                              <div style={{width:"calc(100% - 196px)"}}>
                                <p className="heading mt-2 fs-14">SPRO staket + MegaPower value</p>
                              </div>
                              <div className="mx-2"  style={{width:"120px"}}>
                                <Field name="totalMegaPower" id="totalMegaPower" type="text" className="w-100 input form-control" placeholder="4,500,000"
                                />
                                {errors.totalMegaPower && touched.totalMegaPower ? (
                                  <div className="input-error text-danger">{errors.totalMegaPower}</div>
                                ) : null}
                              </div>
                              <div style={{width:"60px"}}>
                                <p className="token mt-2">Megapower</p>
                              </div>
                            </div>
                            <div className="d-flex my-4">
                              <div style={{width:"calc(100% - 196px)"}}>
                                <p className="heading fs-14">Actual staked from the blockchain</p>
                              </div>
                              <div className="mx-2"  style={{width:"120px"}}>
                                <Field name="boosterPlan" id="boosterPlan" type="text" className="w-100 input form-control" placeholder="457,895"
                                />
                                {errors.boosterPlan && touched.boosterPlan ? (
                                  <div className="input-error text-danger">{errors.boosterPlan}</div>
                                ) : null}
                              </div>
                              <div style={{width:"60px"}}>
                                <p className="token mt-2">SPRO</p>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="">
                              <div className="row">

                                <div className="col-12 ">
                                  <div className="totalMegaPower">
                                    <p className="text-center heading fs-14 px-2 py-3 mb-0">Total MegaPower from blockchain including your calculation <strong className=""> {blockchainMegaPower} SPRO </strong> MegaPower </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row my-3">
                          <div className="col-6 ">
                            <div className="calculatedAmount">
                              <div className="row">
                                <div className="col-12">
                                  <p  style={{ textAlign: "center"}} className="heading fs-14 px-2 py-3 mb-0">Your dividend will be approx <strong className="bold"> { dividend == 'NaN' ? "0.00": dividend} SPRO </strong>& <strong className="" >  ${dividend == 'NaN' ? "0.00": (dividend * Number(data.conversionRate)).toFixed(2)}</strong> </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-2"></div> */}
                          <div className="col-6 ">
                            <div className="calculatedAmount">
                              <div className="row">
                                <div className="col-12">
                                  <p style={{ textAlign: "center"}} className="heading fs-14 px-2 py-3 mb-0">Your daily yield % is  <strong className="">
                                  { dividend == 'NaN' ? "0.00": (((dividend / Number(data.blockReward)) * 100).toFixed(2))}%
                                  </strong> of the pool</p>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 pt-4 mt-2">
                            <div className="d-flex " style={{ justifyContent: "flex-end" }}>
                              <Button type="submit" color="success">
                                Update
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </CCardBody>
            </CCard>
          </div>
        </Container>
      </div>
    </>
  )
}

export default LandingPageCalculator
