import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

import { Button, Card, CardBody, Container, Row } from "reactstrap"
import moment from "moment"
import { cilCopy } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import { Tooltip } from "react-bootstrap"
import searchicon from "../../assets/images/search-line.svg"
import calendarremovelines from "../../assets/images/calendar-remove-lines.svg"
import schedule from "../../assets/images/schedule.svg"
import downloadfileicon from "../../assets/images/download-file.svg"
import DateRangePicker from "react-bootstrap-daterangepicker"
import {
  CCol,
  CButton,
  CFormSelect,
  CInputGroupText,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCard,
  CCardBody,
  CCardGroup,
} from "@coreui/react"
import axios from "axios"
import useApiStatus from "hooks/useApiStatus"
import Spinner from "loader"
import DataTable from "react-data-table-component"
import {
  fullDateFormat,
  StandardPicketDateFormat,
} from "components/Common/utility"
import { ToastContainer, toast } from 'react-toastify'
import "bootstrap-daterangepicker/daterangepicker.css"
import { downloadFile } from "components/Common/utility"
import { api } from "common/api"

const debounceTime = 300
let distinctUntilChanged = null

const UserManagement = () => {
  const user = localStorage.getItem("authUser")
  const parseData = JSON.parse(user)
  const token = parseData?.data?.token

  const [networkStatus, setNetworkStatus] = useState("")

  const [transactionData, setTransactionData] = useState([])
  const { apiStatus, setApiSuccess, setApiFailed, changeApiStatus } =
    useApiStatus()

  const [tooltip, setTooltip] = useState(false)

  const [loader, setLoader] = useState(true)

  const [data, setData] = useState([])
  const [pageData, setPageData] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 8,
  })
  const [status, setStatus] = useState("")

  const [dateFilter, setDateFilter] = useState([])

  const [calendarIsShowing, setCalendarIsShowing] = useState(false)
  const inputRef = useRef(null)
  const [query, setQuery] = useState("")

  const dateFilterChange = (e, picker) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>.", picker)
    // console.log(picker.startDate.format("YYYY-MM-DD"), ">>>>>>>>>>>>>>>>.")

    const filter = [
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD"),
    ]
    setDateFilter(filter)
    fetchData(pageData.current, pageData.pageSize, filter, query)
  }
  const [refresApi, setRefreshApi] = useState(false)
  const fetchData = async (
    page = 1,
    limit = 8,
    dateFilter = [],
    query = "",
    exportRequest = "false",
    networkStatus = ""
  ) => {
    try {
      changeApiStatus(true, "")
      const [startDate, endDate] =
        dateFilter.length === 0 ? ["", ""] : dateFilter

      // console.log(limit,page,"limit and page")
      const list = await api.get(
        `/user/getAll?pageSize=${limit}&pageNumber=${page}&toDate=${endDate}&fromDate=${startDate}&search=${query}&exportRequest=${exportRequest}&filter=${networkStatus}`,
        { headers: { Authorization: `${token}` } }
      )
      console.log(list, "Export csv resp outer")
      if (exportRequest === "true") {
        console.log(list, "Export csv resp")
        const url = URL.createObjectURL(
          new Blob([list.data], { type: "text/csv" })
        )

        const date1 = moment(Date.now()).format("DD-MM-YYYY")
        const time1 = moment(Date).format("h:mm a")

        const finalDate = date1 + "-" + time1

        console.log(date1, "final Date ")
        downloadFile(url, `AllUSers${date1}.csv`)

        return changeApiStatus(false, "")
      }

      if (list.status === 200) {
        console.log(list, "list")
        changeApiStatus(false, "")
        setPageData({
          ...pageData,
          totalItems: list.data.data.totalItems,
          current: page,
        })
        setData(
          list.data.data.items.map((val, index) => {
            return { ...val, serial: index + 1 }
          })
        )
      } else {
        console.log(list)
        throw new Error(list.error)
      }
    } catch (err) {
      console.log(err, ">>>>>>>>>>>>")
      changeApiStatus(false)
    }
  }

  useEffect(() => {
    fetchData(
      pageData.current,
      pageData.pageSize,
      dateFilter,
      query,
      false,
      networkStatus
    )
    // eslint-disable-next-line
  }, [pageData.current, pageData.pageSize, networkStatus, refresApi])

  const onQueryChange = e => {
    const value = e.target.value.trim()
    setQuery(value)
    if (distinctUntilChanged) {
      clearTimeout(distinctUntilChanged)
      distinctUntilChanged = null
    }
    distinctUntilChanged = setTimeout(() => {
      setQuery(value)
      fetchData(pageData.current, pageData.pageSize, dateFilter, value)
      distinctUntilChanged = null
    }, debounceTime)
  }

  const handleChangeCheck = async (id, status) => {

    await api
      .put(`/user/userGoogleAuthentication`, { userId: `${id}`, googleAuthentication: `${status ? "OFF" : "ON"}` }, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then(function (response) {
        toast.success(response.data.message)
        setRefreshApi(!refresApi)
      })
      .catch(function (error) {
        toast.error(error.response.data.error)
        setRefreshApi(!refresApi)
      })
  }


  const columns = [
    {
      name: "Sr. no",
      selector: row => {
        return row.serial + (pageData.current - 1) * pageData.pageSize
      },
      sortable: true,
    },
    {
      name: "User Name",
      selector: row => (
        <div className="cursor-pointer">
          {row?.userName}
        </div>
      ),
      sortable: true,
    },
    {
      name: "User Email",
      selector: row => (
        <>
          <div className="d-flex align-items-center">
            <CIcon
              icon={cilCopy}
              style={{
                height: "18px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigator.clipboard.writeText(row?.email)
                setTooltip(row?.email)

                setTimeout(() => {
                  setTooltip("")
                }, 1200)
              }}
            />
            {tooltip === row?.email ? (
              <div
                style={{
                  position: "absolute",
                  top: "-8px",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "5px",
                  padding: "2px",
                }}
              >
                Copied!
              </div>
            ) : (
              " "
            )}
            <p className="mx-1 mb-0">{row?.email?.slice(0, 16) + "..."}</p>
          </div>
        </>
      ),
      sortable: true,
    },

    {
      name: "User Type",
      selector: row => (
        <>
          <div className="align-items-center">
            <p className="mx-1 mb-0 ">{row?.type}</p>
          </div>
        </>
      ),
    },

    {
      name: "Registered on",
      selector: row => fullDateFormat(row?.createdAt),
      sortbale: true,
    },

  
    {
      name: "View Plan",
      selector: row => (
        <>
          <div className="align-items-center">
            {row?.planExists ?
              <Link to={`/UserTransaction?id=${row?._id}`}>
                <CButton
                  color="success fs-14"
                  className="hand text-white px-3 "
                >
                  View
                </CButton>
              </Link>
              :
              <CButton
              color="success fs-14"
              className="hand text-white px-3 "
              disabled
            >
              View
            </CButton>
            }
          </div>
        </>
      ),
      sortbale: true,
    },
  ]

  return apiStatus.inProgress && !data.length ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <p style={{ color: "#2a3042", fontWeight: 500, fontSize: "17px" }}>
            All Users
          </p>
          <Row>
            <CCol xs>
              <div className="custom-header mb-3">
                <div className="col-12 ml-auto me-auto">
                  <div className="row justify-content-between align-items-center px-0">
                    <div className="col-md-6 col-sm-12 mt-lg-0 mt-2 mt-xl-3">
                      <div className="row align-items-center justify-content-between justify-content-lg-start px-0">
                        <div className="col-md-6 col-12 pb-2 pb-md-0 ">
                          <CCol
                            xs="auto"
                            className="position-relative date_picker"
                          >
                            <CFormLabel
                              className="visually-hidden"
                              htmlFor="autoSizingInputGroup"
                            >
                              Filter by Date
                            </CFormLabel>
                            <CInputGroup style={{ width: "100%" }}>
                              <DateRangePicker
                                onApply={dateFilterChange}
                                onShow={() => setCalendarIsShowing(true)}
                                onHide={() => setCalendarIsShowing(false)}
                              >
                                <input
                                  ref={inputRef}
                                  readOnly
                                  id="file-input"
                                  placeholder="Filter by Date"
                                  className="form-control"
                                  style={{ caretColor: "rgba(0,0,0,0)" }}
                                  value={
                                    dateFilter.length
                                      ? `${StandardPicketDateFormat(
                                        dateFilter[0]
                                      )} - ${StandardPicketDateFormat(
                                        dateFilter[1]
                                      )}`
                                      : ""
                                  }
                                />
                              </DateRangePicker>
                              {console.log(pageData, "pagedata")}
                              <CInputGroupText>
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      !calendarIsShowing &&
                                      dateFilter?.length
                                    ) {
                                      console.log("DANGER CLOCKEDDD")
                                      setDateFilter([])
                                      fetchData(
                                        pageData.current,
                                        pageData.pageSize,
                                        []
                                      )
                                    }
                                  }}
                                  src={
                                    calendarIsShowing
                                      ? calendarremovelines
                                      : dateFilter.length
                                        ? calendarremovelines
                                        : schedule
                                  }
                                  alt=""
                                  width={20}
                                />
                              </CInputGroupText>
                            </CInputGroup>
                          </CCol>
                        </div>
                        <div className="col-md-6 col-12 pb-2 pb-md-0 ">
                          <CCol xs="auto">
                            <CFormLabel
                              className="visually-hidden"
                              htmlFor="autoSizingInputGroup"
                            >
                              Search
                            </CFormLabel>
                            <CInputGroup>
                              <CFormInput
                                id="autoSizingInputGroup"
                                placeholder="Search Email"
                                type="search"
                                value={query}
                                onChange={onQueryChange}
                                style={{ fontSize: "14px" }}
                              />
                              <CInputGroupText>
                                <img
                                  src={searchicon}
                                  alt=""
                                  width={15}
                                  style={{ cursor: "pointer" }}
                                />
                              </CInputGroupText>
                            </CInputGroup>
                          </CCol>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12 mt-lg-0 mt-2 mt-xl-3">
                      <div className="row align-items-center justify-content-end">
                        <div className="col-xxl-5 col-6">
                          <div className="d-flex align-items-center justify-content-lg-end pe-xl-3 pe-2">
                            <div className=" pe-md-3 pe-1 fs-14">Status</div>
                            <div className="">
                              <CFormSelect
                                className="form-control"
                                aria-label="Small select example"
                                onChange={e => setNetworkStatus(e.target.value)}
                                value={networkStatus}
                              >
                                {console.log(networkStatus, "Network status")}
                                <option hidden>Select Network</option>
                                <option value={""}>All</option>
                                <option value="X2">X2</option>
                                <option value="X3">X3</option>
                                {/* {network?.map((content, i) => {
                                                                    return (
                                                                        <>
                                                                            <option
                                                                                key={i}
                                                                                value={content.categoryName}
                                                                            >
                                                                                {content.categoryName}
                                                                            </option>
                                                                        </>
                                                                    )
                                                                })} */}
                              </CFormSelect>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-7 col-6 text-end text-end">
                          <CButton
                            onClick={() =>
                              fetchData(
                                pageData.current,
                                pageData.pageSize,
                                dateFilter,
                                query,
                                "true",
                                networkStatus
                              )
                            }
                            color="success fs-14"
                            className="hand text-white px-2 w-100"
                          >
                            <img
                              src={downloadfileicon}
                              alt=""
                              width={15}
                              className="me-2"
                            />
                            Export CSV
                          </CButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
            <CCardGroup>
              <CCard>
                <CCardBody>
                  <DataTable
                    striped
                    columns={columns}
                    data={data}
                    paginationDefaultPage={pageData.current}
                    paginationPerPage={pageData.pageSize}
                    pagination={true}
                    progressPending={apiStatus.inProgress}
                    paginationServer
                    paginationTotalRows={pageData.totalItems}
                    paginationRowsPerPageOptions={[8, 16, 24, 32]}
                    onChangePage={e => setPageData({ ...pageData, current: e })}
                    onChangeRowsPerPage={e =>
                      setPageData({ ...pageData, pageSize: e })
                    }
                    progressComponent={<Spinner />}
                  />
                </CCardBody>
              </CCard>
            </CCardGroup>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserManagement.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(UserManagement)
