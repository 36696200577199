import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Container, Row } from "reactstrap"
import moment from "moment"
import { cilCopy } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import { Tooltip } from "react-bootstrap"
import refreshImage from "../../assets/images/refresh1.png"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { OverlayTrigger, Popover } from "react-bootstrap"
import './activeNft.scss'
import searchicon from "../../assets/images/search-line.svg"
import calendarremovelines from "../../assets/images/calendar-remove-lines.svg"
import schedule from "../../assets/images/schedule.svg"
import downloadfileicon from "../../assets/images/download-file.svg"
import DateRangePicker from "react-bootstrap-daterangepicker"
import {
  CCol,
  CButton,
  CFormSelect,
  CInputGroupText,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCard,
  CCardBody,
  CCardGroup,
} from "@coreui/react"
import axios from "axios"
import useApiStatus from "hooks/useApiStatus"
import Spinner from "loader"
import DataTable from "react-data-table-component"
import {
  fullDateFormat,
  StandardPicketDateFormat,
} from "components/Common/utility"

import "bootstrap-daterangepicker/daterangepicker.css"
import { downloadFile } from "components/Common/utility"
import { api } from "common/api"

const debounceTime = 300
let distinctUntilChanged = null

export const copyTextToClipboard = element => {
  /* Select the text field /
  element.select();
  element.setSelectionRange(0, 99999); / For mobile devices /

  / Copy the text inside the text field */
  navigator.clipboard.writeText(element.value)
}

const ActiveNft = () => {
  const user = localStorage.getItem("authUser")
  const parseData = JSON.parse(user)
  const token = parseData?.data?.token

  const [emailSearch, setemailSearch] = useState("")

  const [transactionData, setTransactionData] = useState([])
  const { apiStatus, setApiSuccess, setApiFailed, changeApiStatus } =
    useApiStatus()

  const [loader, setLoader] = useState(true)

  const [data, setData] = useState([])
  const [pageData, setPageData] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 8,
  })
  const [status, setStatus] = useState("")

  const [dateFilter, setDateFilter] = useState([])

  const [calendarIsShowing, setCalendarIsShowing] = useState(false)
  const inputRef = useRef(null)
  const [query, setQuery] = useState("")
  const [tooltip, setTooltip] = useState(false)

  const dateFilterChange = (e, picker) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>.", picker)
    // console.log(picker.startDate.format("YYYY-MM-DD"), ">>>>>>>>>>>>>>>>.")

    // cosole.log(pageData, ">>>>>>>>>>>>>>>>>>pagedata")

    const filter = [
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD"),
    ]
    setDateFilter(filter)
    fetchData(pageData.current, pageData.pageSize, filter, query)
  }

  const [network, setNetwork] = useState()

  const fetchNetwork = () => {
    changeApiStatus(true)
    axios
      .get("https://tokenmaker-apis.block-brew.com/network/networkdetails")
      .then(res => {
        setNetwork(res.data.msg.items)

        changeApiStatus(false)
      })
      .catch(err => {
        console.log(err)
        changeApiStatus(false)
        setApiFailed(err.message)
      })
  }

  const [refresh, setRefresh] = useState(true)
  useEffect(() => {
    api
      .get("/transactions/refreshTnxStats", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then(res => {
        //  console.log(res,"refresh")
      })
      .catch(err => {
        console.log(err, "refresh err")
      })
  }, [refresh])

  const fetchData = async (
    page = 1,
    limit = 8,
    dateFilter = [],
    query = "",
    exportRequest = "false",
    emailSearch = ""
  ) => {
    try {
      changeApiStatus(true, "")
      const [startDate, endDate] =
        dateFilter.length === 0 ? ["", ""] : dateFilter

      // console.log(limit,page,"limit and page")
      const list = await api.get(
        `/transactions/getActivePlans?pageSize=${limit}&toDate=${endDate}&pageNumber=${page}&search=${query}&fromDate=${startDate}&filter=${emailSearch}&exportRequest=${exportRequest.toString()}`,
        { headers: { Authorization: `${token}` } }
      )
      if (exportRequest === "true") {
        const url = URL.createObjectURL(
          new Blob([list?.data?.data], { type: "text/csv" })
        )

        const date1 = moment(Date.now()).format("DD-MM-YYYY")
        const time1 = moment(Date).format("h:mm a")
        console.log(list, "listttttt>>>>>>>>>>.>..")
        const finalDate = date1 + "-" + time1

        downloadFile(url, `Transactions${date1}.csv`)

        return changeApiStatus(false, "")
      }

      if (list.status === 200) {
        changeApiStatus(false, "")
        setPageData({
          ...pageData,
          totalItems: list.data.data.totalItems,
          current: page,
        })
        // console.log(list.data.data.items, "testing")
        setData(
          list.data.data.items.map((val, index) => {
            return { ...val, serial: index + 1 }
          })
        )
      } else {
        console.log(list)
        throw new Error(list.error)
      }
    } catch (err) {
      console.log(err, ">>>>>>>>>>>>")
      changeApiStatus(false)
    }
  }

  const [showSuccessToolTip, setShowSuccessToolTip] = useState()
  const showHandler = i => {
    setShowSuccessToolTip(i)
    setTimeout(() => {
      setShowSuccessToolTip()
    }, 2000)
  }

  const [showSuccessToolTip1, setShowSuccessToolTip1] = useState()
  const showHandler1 = i => {
    setShowSuccessToolTip1(i)
    setTimeout(() => {
      setShowSuccessToolTip1()
    }, 2000)
  }

  useEffect(() => {
    fetchNetwork()
  }, [])

  useEffect(() => {
    fetchData(
      pageData.current,
      pageData.pageSize,
      dateFilter,
      query,
      false,
      emailSearch
    )
    // eslint-disable-next-line
  }, [pageData.current, pageData.pageSize, emailSearch, refresh])

  const onQueryChange = e => {
    const value = e.target.value.trim()
    setQuery(value)
    if (distinctUntilChanged) {
      clearTimeout(distinctUntilChanged)
      distinctUntilChanged = null
    }
    distinctUntilChanged = setTimeout(() => {
      setQuery(value)
      fetchData(pageData.current, pageData.pageSize, dateFilter, value)
      distinctUntilChanged = null
    }, debounceTime)
  }
  let statusColor = {
    red: "2+ month late",
    yellow: "1+ month late",
    green: "Paid",
  }

  const columns = [
    {
      name: "Sr. no",
      selector: row => {
        return row.serial + (pageData.current - 1) * pageData.pageSize
      },
      sortable: true,
    },

    {
      name: "NFT Number",
      selector: row => (
        // row.supplyType[0].toUpperCase() + row.supplyType.slice(1)
        <div className="d-flex align-items-center">
          <OverlayTrigger
            show={showSuccessToolTip === row.serial}
            overlay={
              <Popover
              // className={styles.tooltip}
              >
                Copied!
              </Popover>
            }
          >
            <CopyToClipboard
              text={row?._id}
              onCopy={() => showHandler(row.serial)}
            >
              <CIcon
                icon={cilCopy}
                style={{
                  height: "14px",
                  cursor: "pointer",
                }}
              ></CIcon>
            </CopyToClipboard>
          </OverlayTrigger>
          <div> &nbsp; {row?._id.slice(0, 6) + ".."}</div>
        </div>
      ),
    },
    {
      name: "User Email",
      // selector: row => row?.["user"]?.email,
      selector: row => (
        <div className="d-flex align-items-center">
          <OverlayTrigger
            show={showSuccessToolTip1 === row.serial}
            overlay={
              <Popover
              // className={styles.tooltip}
              >
                Copied!
              </Popover>
            }
          >
            <CopyToClipboard
              text={row?.["user"]?.email}
              onCopy={() => showHandler1(row.serial)}
            >
              <CIcon
                icon={cilCopy}
                style={{
                  height: "14px",
                  cursor: "pointer",
                }}
              ></CIcon>
            </CopyToClipboard>
          </OverlayTrigger>
          <div> &nbsp; {row?.["user"]?.email.slice(0, 9) + ".."}</div>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Booster Variant",
      selector: row => (
        // row.supplyType[0].toUpperCase() + row.supplyType.slice(1)
        <>
          <div className="align-items-center">
            <p className="mx-1 mb-0 px-4">{`${row.planName}`}</p>
          </div>
        </>
      ),
    },

    {
      name: "Installment Plan",
      selector: row => `${row?.numOfInstallments} years`,
    },

    {
      name: "Total Amount USD",
      selector: row => `$ ${row?.totalAmount}`,
      sortbale: true,
    },
    {
      name: "Paid Amount in USD",
      selector: row => `$ ${row?.paidAmountUsd} `,
      sortbale: true,
    },
    {
      name: "Purchased on",
      selector: row => fullDateFormat(row.createdAt),
      sortbale: true,
    },
    {
      name: "Due Date",
      selector: row => fullDateFormat(row.nextInstallmentDate),
      sortbale: true,
    },
    // {
    //   name: "Transaction Id",
    //   selector: row => (
    //     <>
    //       <div className="d-flex align-items-center">
    //         <OverlayTrigger
    //           show={showSuccessToolTip === row.serial}
    //           overlay={
    //             <Popover
    //             // className={styles.tooltip}
    //             >
    //               Copied!
    //             </Popover>
    //           }
    //         >
    //           <CopyToClipboard
    //             text={
    //               row?.transactionHash === ""
    //                 ? row?.paymentId
    //                 : row?.transactionHash
    //             }
    //             onCopy={() => showHandler(row.serial)}
    //           >
    //             <CIcon
    //               icon={cilCopy}
    //               style={{
    //                 height: "14px",
    //                 cursor: "pointer",
    //               }}
    //             ></CIcon>
    //           </CopyToClipboard>
    //         </OverlayTrigger>
    //         <div>
    //           {" "}
    //           &nbsp;{" "}
    //           {row?.transactionHash === "" ? (
    //             row.paymentId?.slice(0, 3) +
    //             "..." +
    //             row.paymentId?.slice(row.paymentId.length - 2)
    //           ) : (
    //             <a
    //               className="text-black"
    //               href={`https://bscscan.com/tx/${row?.transactionHash}`}
    //               target="_blank"
    //               rel="noreferrer"
    //             >
    //               {" "}
    //               {row.transactionHash?.slice(0, 3) +
    //                 "..." +
    //                 row?.transactionHash.slice(
    //                   row?.transactionHash?.length - 2
    //                 )}
    //             </a>
    //           )}{" "}
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },

    {
      name: "Status",

      selector: row => (
        <CButton
        
          className=""
          style={{
            border: row.color,
            backgroundColor: row.color,
            color: "white",
            fontWeight: "600",
            width: "110px",
            cursor: "unset",
          }}
        >
          {statusColor[row?.color]}
        </CButton>
      ),
    },
  ]

  return apiStatus.inProgress && !data.length ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <p style={{ color: "#2a3042", fontWeight: 500, fontSize: "17px" }}>
            Active NFT
          </p>
          <Row>
            <CCol xs>
              <div className="custom-header mb-3">
                <div className="col-xxl-12 col-xl-12 col-12 ml-auto me-auto">
                  <div className="row justify-content-between align-items-center px-0">
                    <div className="col-lg-6 col-md-12 mt-lg-0 mt-2 mt-xl-3">
                      <div className="row align-items-center justify-content-between justify-content-lg-start ">
                        <div className="col-xl-5 col-sm-6 pb-2 pb-md-0 ">
                          <CCol
                            xs="auto"
                            className="position-relative date_picker"
                          >
                            <CFormLabel
                              className="visually-hidden"
                              htmlFor="autoSizingInputGroup"
                              style={{ fontSize: "14px" }}
                            >
                              Filter by Date
                            </CFormLabel>
                            <CInputGroup style={{ width: "100%" }}>
                              <DateRangePicker
                                onApply={dateFilterChange}
                                onShow={() => setCalendarIsShowing(true)}
                                onHide={() => setCalendarIsShowing(false)}
                              >
                                <input
                                  ref={inputRef}
                                  readOnly
                                  id="file-input"
                                  placeholder="Filter by Date"
                                  className="form-control"
                                  style={{ caretColor: "rgba(0,0,0,0)" }}
                                  value={
                                    dateFilter.length
                                      ? `${StandardPicketDateFormat(
                                          dateFilter[0]
                                        )} - ${StandardPicketDateFormat(
                                          dateFilter[1]
                                        )}`
                                      : ""
                                  }
                                />
                              </DateRangePicker>
                              <CInputGroupText>
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      !calendarIsShowing &&
                                      dateFilter.length
                                    ) {
                                      setDateFilter([])
                                      fetchData(
                                        pageData.current,
                                        pageData.pageSize,
                                        []
                                      )
                                    }
                                  }}
                                  src={
                                    calendarIsShowing
                                      ? calendarremovelines
                                      : dateFilter.length
                                      ? calendarremovelines
                                      : schedule
                                  }
                                  alt=""
                                  width={20}
                                />
                              </CInputGroupText>
                            </CInputGroup>
                          </CCol>
                        </div>
                        <div className="col-xl-5 col-sm-6 pb-2 pb-md-0 ">
                          <CCol xs="auto">
                            <CFormLabel
                              className="visually-hidden"
                              htmlFor="autoSizingInputGroup"
                            >
                              Search
                            </CFormLabel>
                            <CInputGroup>
                              <CFormInput
                                id="autoSizingInputGroup"
                                placeholder="Search Email"
                                type="search"
                                value={query}
                                onChange={onQueryChange}
                                style={{ fontSize: "14px" }}
                              />
                              <CInputGroupText>
                                <img
                                  src={searchicon}
                                  alt=""
                                  width={15}
                                  style={{ cursor: "pointer" }}
                                />
                              </CInputGroupText>
                            </CInputGroup>
                          </CCol>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 mt-lg-0 mt-2 mt-xl-3">
                      <div className="row align-items-center justify-content-end">
                        <div className="col-xl-6 col-md-6 col-6">
                          <div className="d-flex align-items-center justify-content-lg-end pe-xl-2 pe-2">
                            <div className=" text-end pe-md-3 pe-1 fs-14">
                              Boosters
                            </div>
                            <div className="">
                              <CFormSelect
                                className="form-control"
                                aria-label="Small select example"
                                onChange={e => setemailSearch(e.target.value)}
                                value={emailSearch}
                              >
                                <option hidden>Status</option>
                                <option value={""} style={{ fontSize: "13px" }}>
                                  All Boosters
                                </option>
                                <option value="X2">X2</option>
                                <option value="X3">X3</option>
                              </CFormSelect>
                            </div>
                            <div
                              style={{
                                padding: "6px",
                                marginLeft: "20px",
                                cursor: "pointer",
                              }}
                              className="bg-white border border-secondary rounded "
                              onClick={() => setRefresh(!refresh)}
                            >
                              <img src={refreshImage} width={20} />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-5 col-md-6 col-6 text-end text-end">
                          <CButton
                            onClick={() =>
                              fetchData(
                                pageData.current,
                                pageData.pageSize,
                                dateFilter,
                                query,
                                "true",
                                emailSearch
                              )
                            }
                            color="success fs-14"
                            className="hand text-white px-2 w-100"
                          >
                            <img
                              src={downloadfileicon}
                              alt=""
                              width={15}
                              className="me-2"
                            />
                            Export CSV
                          </CButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
            <CCardGroup>
              <CCard className="mb-4 serial_no invest_table">
                <CCardBody>
                  <DataTable
                    striped
                    columns={columns}
                    data={data}
                    paginationDefaultPage={pageData.current}
                    paginationPerPage={pageData.pageSize}
                    pagination={true}
                    progressPending={apiStatus.inProgress}
                    paginationServer
                    paginationTotalRows={pageData.totalItems}
                    paginationRowsPerPageOptions={[8, 16, 24, 32]}
                    onChangePage={e => setPageData({ ...pageData, current: e })}
                    onChangeRowsPerPage={e =>
                      setPageData({ ...pageData, pageSize: e })
                    }
                    progressComponent={<Spinner />}
                  />
                </CCardBody>
              </CCard>
            </CCardGroup>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ActiveNft.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(ActiveNft)
