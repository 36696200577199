import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import React from 'react'
//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link,Route } from "react-router-dom";
import { replace } from "lodash"


// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {

  console.log("using the following url for registration: ")
  try {
    console.log("Trying to register user (within try block)")
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.registerUser,
        user.email,
        user.password
      )
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, "/post-jwt-register", user)
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      console.log('ctrfyguhijovgfgyjhukj');
      console.log(user);
      
      const response = yield call(postFakeRegister, user)
      if (response.success == 1) {
        yield put(registerUserSuccessful(response))
        toast('Register Successful');
        async function hrefChange() {
          setTimeout(() => {
            window.location.href = '/login';
              
          },3000)
        }
        hrefChange();
      } else {
        <Route replace to='/register' />

      }
    console.log(response,'wsdefrtgyhuijok');
    }
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
