import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import { Button, Card, CardBody, Container, Row } from "reactstrap"
import moment from "moment"
import { cilCopy } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { Tooltip } from "react-bootstrap"

// import searchicon from "../../assets/images/search-line.svg"
// import calendarremovelines from "../../assets/images/calendar-remove-lines.svg"
// import schedule from "../../assets/images/schedule.svg"
import downloadfileicon from "../../../assets/images/download-file.svg"
import DateRangePicker from "react-bootstrap-daterangepicker"
import {
  CCol,
  CButton,
  CFormSelect,
  CInputGroupText,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCard,
  CCardBody,
  CCardGroup,
} from "@coreui/react"
import axios from "axios"
import useApiStatus from "hooks/useApiStatus"
import Spinner from "loader"
import DataTable from "react-data-table-component"
import {
  fullDateFormat,
  StandardPicketDateFormat,
} from "components/Common/utility"

import "bootstrap-daterangepicker/daterangepicker.css"
import { downloadFile } from "components/Common/utility"
import { api } from "common/api"

const debounceTime = 300
let distinctUntilChanged = null

export const copyTextToClipboard = (element) => {
  /* Select the text field /
  element.select();
  element.setSelectionRange(0, 99999); / For mobile devices /

  / Copy the text inside the text field */
  navigator.clipboard.writeText(element.value);
};

const Users = () => {
  const user = localStorage.getItem("authUser")
  const parseData = JSON.parse(user)
  const token = parseData?.data?.token

  const [emailSearch, setemailSearch] = useState("")

  const [transactionData, setTransactionData] = useState([])
  const { apiStatus, setApiSuccess, setApiFailed, changeApiStatus } =
    useApiStatus()

  const [loader, setLoader] = useState(true)


  const [data, setData] = useState([])
  const [pageData, setPageData] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 8,
  })
  const [status, setStatus] = useState("")

  const [dateFilter, setDateFilter] = useState([])

  const [calendarIsShowing, setCalendarIsShowing] = useState(false)
  const inputRef = useRef(null)
  const [query, setQuery] = useState("")
  const [tooltip, setTooltip] = useState(false)

  const dateFilterChange = (e, picker) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>.", picker)
    // console.log(picker.startDate.format("YYYY-MM-DD"), ">>>>>>>>>>>>>>>>.")

    const filter = [
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD"),
    ]
    setDateFilter(filter)
    fetchData(pageData.current, pageData.pageSize, filter, query)
  }

  const [network, setNetwork] = useState()


  const fetchNetwork = () => {
    changeApiStatus(true)
    axios
      .get("https://tokenmaker-apis.block-brew.com/network/networkdetails")
      .then(res => {
        setNetwork(res.data.msg.items)

        changeApiStatus(false)
      })
      .catch(err => {
        console.log(err)
        changeApiStatus(false)
        setApiFailed(err.message)
      })
  }



  const fetchData = async (
    page = 1,
    limit = 8,
    dateFilter = [],
    query = "",
    exportRequest = "false",
    emailSearch = ""
  ) => {
    try {
      changeApiStatus(true, "")
      const [startDate, endDate] =
        dateFilter.length === 0 ? ["", ""] : dateFilter

      // console.log(limit,page,"limit and page")
      const list = await api.get(
        `/newsletter/getAll?pageSize=${limit}&pageNumber=${page}&toDate=${endDate}&fromDate=${startDate}&filter=${emailSearch}&exportRequest=${exportRequest.toString()}`,
        { headers: { Authorization: `${token}` } }
      )
      if (exportRequest === "true") {
        console.log(list, "list")
        const url = URL.createObjectURL(
          new Blob([list.data], { type: "text/csv" })

        )

        const date1 = moment(Date.now()).format("DD-MM-YYYY")
        const time1 = moment(Date).format(('h:mm a'))

        const finalDate = date1 + "-" + time1


        downloadFile(url, `Subscribers${date1}.csv`)

        return changeApiStatus(false, "")
      }

      if (list.status === 200) {
        changeApiStatus(false, "")
        setPageData({
          ...pageData,
          totalItems: list.data.data.totalItems,
          current: page,
        })
        setData(
          list.data.data.items.map((val, index) => {
            return { ...val, serial: index + 1 }
          })
        )
      } else {
        console.log(list)
        throw new Error(list.error)
      }
    } catch (err) {
      console.log(err, ">>>>>>>>>>>>")
      changeApiStatus(false)
    }
  }

  useEffect(() => {
    fetchNetwork()
  }, [])

  useEffect(() => {

    fetchData(pageData.current, pageData.pageSize, dateFilter, query, false, emailSearch)
    // eslint-disable-next-line
  }, [pageData.current, pageData.pageSize, emailSearch])

  const onQueryChange = e => {
    const value = e.target.value.trim()
    setQuery(value)
    if (distinctUntilChanged) {
      clearTimeout(distinctUntilChanged)
      distinctUntilChanged = null
    }
    distinctUntilChanged = setTimeout(() => {
      setQuery(value)
      fetchData(pageData.current, pageData.pageSize, dateFilter, value)
      distinctUntilChanged = null
    }, debounceTime)
  }


  const columns = [
    {
      name: "Sr. no",
      selector: row => {
        return row.serial + (pageData.current - 1) * pageData.pageSize
      },
      sortable: true,
    },
    {
      name: "User Name",
      selector: row => row?.userName,
      sortable: true,
    },

    {
      name: 'Email',
      selector: (row) => row.email,
    },

    {
      name: "Subscribed on",
      selector: row => fullDateFormat(row.createdAt),
      sortbale: true,
    },


    // {
    //   name: "View Transactions",
    //   selector: row => (
    //     <>
    //       <div className="d-flex">
    //       <p>{row.transactionHash}</p>
    //       <CIcon
    //         icon={cilCopy}
    //         style={{
    //           height: "18px",
    //           cursor: "pointer"
    //         }}
    //         onClick={() => {
    //           navigator.clipboard.writeText(deployData.tokenAddress)
    //           setTooltip(true)

    //           setTimeout(() => {
    //             setTooltip(false)
    //           }, 1200)
    //         }}
    //       />
    //       </div>
    //     </>
    //   ),
    // },
  ]

  return apiStatus.inProgress && !data.length ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Row>
            <CCol xs>
              <div className="custom-header mb-3">
                <div className="col-xxl-12 col-xl-12 col-12 ml-auto me-auto">
                  <div className="row justify-content-between align-items-center px-0">
                    <div className="col-6 mt-lg-0 mt-2 mt-xl-3">
                      <div className="row align-items-center justify-content-between justify-content-lg-start px-0">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <CCol xs="auto" className="position-relative date_picker">
                            <p
                              style={{ color: "#2a3042", fontWeight: 500, fontSize: "17px", marginBottom: "0" }} 
                            >Subscribed Users</p>
                            <CInputGroup style={{ width: "100%" }}>

                              {/* {console.log(pageData, "pagedata")} */}

                            </CInputGroup>
                          </CCol>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 mt-lg-0 mt-2 mt-xl-3">
                      <div className=" align-items-center justify-content-end"> 
                        <div className=" text-end text-end">
                          <CButton
                            onClick={() =>
                              fetchData(
                                pageData.current,
                                pageData.pageSize,
                                dateFilter,
                                query,
                                "true",
                                emailSearch,
                              )
                            }
                            color="success"
                            className="hand text-white px-2 w-100 fs-14"
                          >
                            <img
                              src={downloadfileicon}
                              alt=""
                              width={15}
                              className="me-2"
                            />
                            Export CSV
                          </CButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
            <CCardGroup className="mb-4">
              <CCard>
                <CCardBody>
                  <DataTable
                    striped
                    columns={columns}
                    data={data}
                    paginationDefaultPage={pageData.current}
                    paginationPerPage={pageData.pageSize}
                    pagination={true}
                    progressPending={apiStatus.inProgress}
                    paginationServer
                    paginationTotalRows={pageData.totalItems}
                    paginationRowsPerPageOptions={[8, 16, 24, 32]}
                    onChangePage={e => setPageData({ ...pageData, current: e })}
                    onChangeRowsPerPage={e =>
                      setPageData({ ...pageData, pageSize: e })
                    }
                    progressComponent={<Spinner />}
                  />
                </CCardBody>
              </CCard>
            </CCardGroup>
          </Row>

        </Container>
      </div>


    </React.Fragment>
  )
}

Users.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Users)
