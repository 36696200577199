import PropTypes from "prop-types";
import React, { useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { useCookies } from 'react-cookie'
//redux
import { useSelector, useDispatch } from "react-redux";
import Spinner from "loader"
import { withRouter, Link } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports

// import TwitterLogin from "react-twitter-auth"

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/smartchain/spro.png";

//Import config
import { facebook, google } from "../../config";
import { RestoreFromTrash } from "@mui/icons-material";
import axios from "axios";
import { api } from "common/api";

const Login = props => {
  const [cookies, setCookie] = useCookies(['user'])
  //meta title
  document.title = "Login |  SmartChain Pro";
  const user = localStorage.getItem("credentials")
  const parseData = JSON.parse(user)
  const useremail = parseData?.email
  const userpassword = parseData?.password
  const  [googleAuth, setGoogleAuth]=useState(false)
  const [googleotp, setGoogleotp]=useState("")
  const [qrcode, setQrcode] = useState("");
  const [userId, setUserId] = useState("");
  const [loader, setLoader]=useState(false)
  const cookieSet = () => {
    setCookie('user', useremail, { path: "/" })
    setCookie('password', userpassword, { path: "/" })
  }
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: useremail,
      password: userpassword,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loginUser(values, props.history));
      // setQrcode(JSON.parse(localStorage.getItem('authUser'))?.data?.qrcode);
      // setUserId(JSON.parse(localStorage.getItem('authUser'))?.data?.userId);
      // toast.success("Login Successfully")
      resetForm(values = '');
      // setGoogleAuth(true)
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook");
  };

const gooleAuthLogin =async()=>{
  setLoader(true)
 await api
  .post("/user/verifyGoogleOtp", {
    userId: JSON.parse(localStorage.getItem('authUser'))?.data?.userId,
    otp: googleotp,
  }).then((response) => {
      localStorage.setItem("authUser", JSON.stringify(response.data));
      setGoogleotp("");
      setUserId("");
      // history.push("/dashboard")
      window.location.href='/dashboard'
    })
    .catch((err) => {
      setLoader(false)
      console.log(err,'err')
      toast.error("OTP doesn't match")
    });
}

  return (
    <React.Fragment>
      {loader ?
     <Spinner/>
     :
<>
<div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to SmartChain Pro.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img style={{
                        width: '100%', height: '88px'
                        , objectFit: "contain"
                      }} src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className=""
                            height="80px"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  {googleAuth ? 
                <div className="p-2 d-flex justify-content-center align-items-center flex-column ">
                   <h6 className="col-12 text-center text-black mb-4 mt-2 pb-lg-3">
              Scan QR code with Google authenticator for OTP{" "}
            </h6>
                  <img width={200} src={JSON.parse(localStorage.getItem('authUser'))?.data?.qrcode}  />
                  <Label className="form-label">Enter OTP</Label>
                    <Input
                      className="form-control"
                      placeholder="Enter OTP"
                      type="text"
                      autoComplete="off"
                      value={googleotp}
                      onChange={(e)=>setGoogleotp(e.target.value)}
                    />
                    <button
                      className="btn btn-primary btn-block mt-3"
                      onClick={()=>gooleAuthLogin()}
                    >
                      Submit 
                    </button>
                  </div>
                :
                <div className="p-2">
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  {error ? <Alert color="danger">{error}</Alert> : null}

                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      autoComplete="off"
                      invalid={
                        validation.touched.email && validation.errors.email ? true : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      autoComplete="off"
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password && validation.errors.password ? true : false
                      }
                    />
                    {validation.touched.password && validation.errors.password ? (
                      <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-primary btn-block"
                      type="submit"
                    >
                      Log In
                    </button>
                    <div className="form-check d-flex pt-2" style={{justifyContent:'space-around'}}>
                      {/* <div>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          onClick={cookieSet}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}
                      <Link to='/forgot-password'>
                        <p className="">Forgot Password?</p>
                      </Link>
                    </div>
                  </div>
                </Form>
              </div>
                }
                 
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()}  Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by <a href="https://blocktechbrew.com" target="_blank" rel="noreferrer" > Blocktech Brew</a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
</>
    }
     
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
