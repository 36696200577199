import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

import { Button, Card, CardBody, Container, Row } from "reactstrap"
import moment from "moment"
import { cilCopy } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import { Tooltip } from "react-bootstrap"
import searchicon from "../../assets/images/search-line.svg"
import calendarremovelines from "../../assets/images/calendar-remove-lines.svg"
import schedule from "../../assets/images/schedule.svg"
import downloadfileicon from "../../assets/images/download-file.svg"
import DateRangePicker from "react-bootstrap-daterangepicker"
import {
  CCol,
  CButton,
  CFormSelect,
  CInputGroupText,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCard,
  CCardBody,
  CCardGroup,
} from "@coreui/react"
import axios from "axios"
import useApiStatus from "hooks/useApiStatus"
import Spinner from "loader"
import DataTable from "react-data-table-component"
import {
  fullDateFormat,
  StandardPicketDateFormat,
} from "components/Common/utility"
import { ToastContainer, toast } from "react-toastify"
import "bootstrap-daterangepicker/daterangepicker.css"
import { downloadFile } from "components/Common/utility"
import { api } from "common/api"

const debounceTime = 300
let distinctUntilChanged = null

const UserManagement = () => {
  const user = localStorage.getItem("authUser")
  const parseData = JSON.parse(user)
  const token = parseData?.data?.token

  const [networkStatus, setNetworkStatus] = useState("")

  const [transactionData, setTransactionData] = useState([])
  const { apiStatus, setApiSuccess, setApiFailed, changeApiStatus } =
    useApiStatus()

  const [tooltip, setTooltip] = useState(false)

  const [loader, setLoader] = useState(true)

  const [data, setData] = useState([])
  const [pageData, setPageData] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 8,
  })
  const [status, setStatus] = useState("")

  const [dateFilter, setDateFilter] = useState([])

  const [calendarIsShowing, setCalendarIsShowing] = useState(false)
  const inputRef = useRef(null)
  const [query, setQuery] = useState("")

  const dateFilterChange = (e, picker) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>.", picker)
    // console.log(picker.startDate.format("YYYY-MM-DD"), ">>>>>>>>>>>>>>>>.")

    const filter = [
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD"),
    ]
    setDateFilter(filter)
    fetchData(pageData.current, pageData.pageSize, filter, query)
  }
  const [refresApi, setRefreshApi] = useState(false)
  const [rewardX2, setRewardX2] = useState("")
  const [rewardX3, setRewardX3] = useState("")
  const fetchData = async (
    page = 1,
    limit = 8,
    dateFilter = [],
    query = "",
    exportRequest = "false",
    networkStatus = ""
  ) => {
    try {
      changeApiStatus(true, "")
      const [startDate, endDate] =
        dateFilter.length === 0 ? ["", ""] : dateFilter

      // console.log(limit,page,"limit and page")
      const list = await api.get(
        `/products/getAllRewards?pageSize=${limit}&pageNumber=${page}&toDate=${endDate}&fromDate=${startDate}&search=${query}&exportRequest=${exportRequest}&filter=${networkStatus}`,
        { headers: { Authorization: `${token}` } }
      )
      console.log(list, "Export csv resp outer")
      if (exportRequest === "true") {
        console.log(list, "Export csv resp")
        const url = URL.createObjectURL(
          new Blob([list.data], { type: "text/csv" })
        )

        const date1 = moment(Date.now()).format("DD-MM-YYYY")
        const time1 = moment(Date).format("h:mm a")

        const finalDate = date1 + "-" + time1

        console.log(date1, "final Date ")
        downloadFile(url, `AllUSers${date1}.csv`)

        return changeApiStatus(false, "")
      }

      if (list.status === 200) {
        console.log(list, "list121")
        changeApiStatus(false, "")
        setPageData({
          ...pageData,
          totalItems: list.data.data.totalItems,
          current: page,
        })
        setData(
          list.data.data.items.map((val, index) => {
            return { ...val, serial: index + 1 }
          })
        )
      } else {
        console.log(list)
        throw new Error(list.error)
      }
    } catch (err) {
      console.log(err, ">>>>>>>>>>>>")
      changeApiStatus(false)
    }
  }

  useEffect(() => {
    fetchData(
      pageData.current,
      pageData.pageSize,
      dateFilter,
      query,
      false,
      networkStatus
    )
    // eslint-disable-next-line
  }, [pageData.current, pageData.pageSize, networkStatus, refresApi])

  const onQueryChange = e => {
    const value = e.target.value.trim()
    setQuery(value)
    if (distinctUntilChanged) {
      clearTimeout(distinctUntilChanged)
      distinctUntilChanged = null
    }
    distinctUntilChanged = setTimeout(() => {
      setQuery(value)
      fetchData(pageData.current, pageData.pageSize, dateFilter, value)
      distinctUntilChanged = null
    }, debounceTime)
  }

  const columns = [
    {
      name: "Sr. no",
      selector: row => {
        return row.serial + (pageData.current - 1) * pageData.pageSize
      },
      sortable: true,
    },
    {
      name: "User Name",
      selector: row => <div className="cursor-pointer">{row?.userName}</div>,
      sortable: true,
    },
    {
      name: "User Email",
      selector: row => (
        <>
          <div className="d-flex align-items-center">
            <CIcon
              icon={cilCopy}
              style={{
                height: "18px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigator.clipboard.writeText(row?.email)
                setTooltip(row?.email)

                setTimeout(() => {
                  setTooltip("")
                }, 1200)
              }}
            />
            {tooltip === row?.email ? (
              <div
                style={{
                  position: "absolute",
                  top: "-8px",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "5px",
                  padding: "2px",
                }}
              >
                Copied!
              </div>
            ) : (
              " "
            )}
            <p className="mx-1 mb-0">{row?.email?.slice(0, 16) + "..."}</p>
          </div>
        </>
      ),
      sortable: true,
    },

    {
      name: "User Type",
      selector: row => (
        <>
          <div className="align-items-center">
            <p className="mx-1 mb-0 ">{row?.type}</p>
          </div>
        </>
      ),
    },
    {
      name: "Purcahsed Plans",
      selector: row => (
        <>
          <div className="align-items-center">
            {row?.planNames?.map((name, i) => (
              <p className="mx-1 mb-0 " key={i}>
                {name}
              </p>
            ))}
          </div>
        </>
      ),
    },
    {
      name: "Total Amount",
      selector: row => (
        <>
          <div className="align-items-center">
            {row?.plans?.map((plan, i) => (
              <p className="mx-1 mb-0 " key={i}>
                {plan?.planName} : ${plan?.totalAmount}
              </p>
            ))}
          </div>
        </>
      ),
    },
    {
      name: "Pending Amount",
      selector: row => (
        <>
          <div className="align-items-center">
            {row?.plans?.map((currentPlan, i) => {
              let totalInstallmentsDone =
                Number(currentPlan.numOfInstallmentsMonth) -
                Number(currentPlan.remainingInstallmentsMonth)
              let totalInstallmentFeePaid = Number(totalInstallmentsDone) * 5
              let pendingPlanAmount =
                Number(currentPlan.totalAmount) +
                Number(totalInstallmentFeePaid) -
                Number(currentPlan.paidAmountUsd)
              return (
                <p className="mx-1 mb-0 " key={i}>
                  {currentPlan?.planName} : ${pendingPlanAmount}
                </p>
              )
            })}
          </div>
        </>
      ),
    },
    {
      name: "Total Rewards",
      selector: row => (
        <>
          <div className="align-items-center">
            <p className="mx-1 mb-0 ">
              {Intl.NumberFormat("en-IN").format(
                Number(
                  row?.plans?.some(
                    plan => plan.planName == "X2" && plan.paidAmountUsd > 10
                  )
                    ? row?.rewards?.X2 ?? 0
                    : 0
                ) +
                  Number(
                    row?.plans?.some(
                      plan => plan.planName == "X3" && plan.paidAmountUsd > 10
                    )
                      ? row?.rewards?.X3 ?? 0
                      : 0
                  ) +
                  Number(
                    row?.plans?.every(plan => plan.paidAmountUsd > 20)
                      ? row?.rewards?.bonus ?? 0
                      : 0
                  )
              )}{" "}
              SPRO
            </p>
          </div>
        </>
      ),
    },

    // {
    //   name: "Action",
    //   selector: row => (
    //     <>
    //       <div className="align-items-center">
    //         <CButton
    //           color="success fs-14"
    //           className="hand text-white px-3 "
    //           disabled
    //         >
    //           Action
    //         </CButton>
    //       </div>
    //     </>
    //   ),
    //   sortbale: true,
    // },
  ]

  return apiStatus.inProgress && !data.length ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <p style={{ color: "#2a3042", fontWeight: 500, fontSize: "17px" }}>
            Rewards
          </p>
          <Row>
            <CCol xs>
              <div className="custom-header mb-3">
                <div className="col-12 ml-auto me-auto">
                  <div className="d-flex justify-content-end gap-4">
                    <div>
                      <CCol xs="auto">
                        <CFormLabel
                          className="visually-hidden"
                          htmlFor="autoSizingInputGroup"
                        >
                          Search
                        </CFormLabel>
                        <CInputGroup>
                          <CFormInput
                            id="autoSizingInputGroup"
                            placeholder="Search by Email/User-Name"
                            type="search"
                            value={query}
                            onChange={onQueryChange}
                            style={{ fontSize: "14px" }}
                          />
                          <CInputGroupText>
                            <img
                              src={searchicon}
                              alt=""
                              width={15}
                              style={{ cursor: "pointer" }}
                            />
                          </CInputGroupText>
                        </CInputGroup>
                      </CCol>
                    </div>
                    <div>
                      {" "}
                      <div className="">
                        <CFormSelect
                          className="form-control"
                          aria-label="Small select example"
                          onChange={e => setNetworkStatus(e.target.value)}
                          value={networkStatus}
                        >
                          <option value={""}>All</option>
                          <option value="X2">X2</option>
                          <option value="X3">X3</option>
                        </CFormSelect>
                      </div>
                    </div>
                    <div>
                      {" "}
                      <CButton
                        onClick={() =>
                          fetchData(
                            pageData.current,
                            pageData.pageSize,
                            dateFilter,
                            query,
                            "true",
                            networkStatus
                          )
                        }
                        color="success fs-14"
                        className="hand text-white px-5"
                      >
                        <img
                          src={downloadfileicon}
                          alt=""
                          width={15}
                          className="me-2"
                        />
                        Export CSV
                      </CButton>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
            <CCardGroup>
              <CCard>
                <CCardBody>
                  <DataTable
                    striped
                    columns={columns}
                    data={data}
                    paginationDefaultPage={pageData.current}
                    paginationPerPage={pageData.pageSize}
                    pagination={true}
                    progressPending={apiStatus.inProgress}
                    paginationServer
                    paginationTotalRows={pageData.totalItems}
                    paginationRowsPerPageOptions={[8, 16, 24, 32]}
                    onChangePage={e => setPageData({ ...pageData, current: e })}
                    onChangeRowsPerPage={e =>
                      setPageData({ ...pageData, pageSize: e })
                    }
                    progressComponent={<Spinner />}
                  />
                </CCardBody>
              </CCard>
            </CCardGroup>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserManagement.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(UserManagement)
